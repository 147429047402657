<template>
<!--zoom会议室管理-->
  <div class="zoomMeet">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="zoomMeet_header">
        <div>
          会议ID&nbsp;&nbsp;<a-input v-model="searchID" placeholder="输入会议ID搜索" size="default"
                             style="width: 180px" @pressEnter="()=>this.searchOk()"/>&nbsp;&nbsp;&nbsp;
          类型: &nbsp;
          <!-- @change="searchOk" -->
        <a-radio-group v-model="type" style="margin-right: 40px" @change="typeSearchOk" >
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="ZOOM">
            ZOOM
          </a-radio-button>
          <a-radio-button value="TENCENT">
            腾讯会议
          </a-radio-button>
        </a-radio-group>            
          <a-button style="margin-left: 10px" type="primary" @click="searchOk">查询</a-button>
          <a-button style="margin-left: 10px" @click="searchReset">重置</a-button>
        </div>
      </div>
      <div class="zoomMeet_table">
        <a-button style="margin-bottom: 10px" type="primary" @click="addBtn">新增</a-button>
        <a-table style="margin-bottom: 5px" :columns="columns" :scroll="{ x: 1500}" :data-source="InfoTable" :pagination="false" rowKey="id"
        >
          <span slot="state" slot-scope="text,item">
            <a-switch :defaultChecked="item.status=== 'ENABLE' ? true:false" checked-children="是" un-checked-children="否" @click="switchChange(item.id,item.status === 'ENABLE'? 'UNENABLE' : 'ENABLE')"/>
          </span>

          <span slot="operate" slot-scope="text,item">
            <a style="margin-right: 5px" @click="editBtn(item)">编辑</a>
            <a-popconfirm
                title="确定删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delTable(item.id)"
                @cancel="cancel"
            >
              <a >删除</a>
            </a-popconfirm>
          </span>
        </a-table>
        <div style="display: flex;justify-content: flex-end;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="pageChange"/>
        </div>

      </div>





      <!----------弹窗----------------->
      <a-drawer
          title="新增"
          :visible="addShow"
          width="500"
          @close="()=>{this.addShow = false;this.addForm={}}"
      >
        <a-form-model ref="addItem" :rules="addForm.type == 'ZOOM' ? rulesZOOM:rules"  :label-col="labelCol" :model="addForm"  :wrapper-col="wrapperCol">
          <a-form-model-item  label="类型"  prop="type" >
            <a-radio-group v-model="addForm.type" >
              <a-radio value='ZOOM'>
                ZOOM
              </a-radio>
              <a-radio value='TENCENT'>
                腾讯会议
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item  label="登陆账号"    prop="loginAccount">
            <a-input v-model="addForm.loginAccount" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="密码"   prop="password" >
            <a-input v-model="addForm.password" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="会议ID"   prop="meetId" >
            <a-input v-model="addForm.meetId" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="会议链接"   prop="meetUrl" >
            <a-input v-model="addForm.meetUrl" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="容量"   prop="capacity" >
            <a-input-number id="inputNumber" v-model="addForm.capacity" placeholder="请输入" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item  label="备注"   >
            <a-textarea v-model="addForm.remark" auto-size placeholder="请输入" style="min-height: 100px"/>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight:8px" @click="()=>{this.addShow = false,this.addForm={}}">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确认
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          title="修改"
          :visible="editShow"
          width="600"
          @close="()=>{this.editShow = false;}"
      >
        <a-form-model ref="editItem"  :rules="rules" :label-col="labelCol" :model="editForm"  :wrapper-col="wrapperCol">
          <a-form-model-item  label="类型"  prop="type" >
            <a-radio-group v-model="editForm.type" >
              <a-radio value='ZOOM'>
                ZOOM
              </a-radio>
              <a-radio value='TENCENT'>
                腾讯会议
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item  label="登陆账号"   prop="loginAccount">
            <a-input v-model="editForm.loginAccount" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="密码"   prop="password" >
            <a-input v-model="editForm.password" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="会议ID"   prop="meetId" >
            <a-input v-model="editForm.meetId" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="会议链接"   prop="meetUrl" >
            <a-input v-model="editForm.meetUrl" auto-size placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="容量"   prop="capacity" >
            <a-input-number  v-model="editForm.capacity" placeholder="请输入" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item  label="备注"   >
            <a-textarea v-model="editForm.remark" auto-size placeholder="请输入" style="min-height: 100px"/>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editShow = false;this.editForm={}}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>







    </a-spin>
  </div>


</template>

<script>
import {delZoomMeet, getZoomMeet, postZoomMeet, putZoomMeet} from "@/service/zoomMeet_api";
import {getCommunictionList} from "@/service/liveMeet_api";

export default {
  name: "zoomMeet",
  data(){
    return{
      type:'',
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin/>,
      searchID:null,
      addShow: false,
      editShow: false,
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      rules: {
        loginAccount:[{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        password:[{ required: true, message: '请输入密码', trigger: 'blur' }],
        meetId:[{ required: true, message: '请输入会议ID', trigger: 'blur' }],
        capacity:[{ required: true, message: '请输入容量', trigger: 'blur' }],
        type:[{ required: true, message: '请选择类型', trigger: 'blur' }],
        meetUrl:[{ required: true, message: '请输入会议链接', trigger: 'blur' }],
      },
      rulesZOOM: {
        loginAccount:[{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        password:[{ required: true, message: '请输入密码', trigger: 'blur' }],
        meetId:[{ required: true, message: '请输入会议ID', trigger: 'blur' }],
        capacity:[{ required: true, message: '请输入容量', trigger: 'blur' }],
        type:[{ required: true, message: '请选择类型', trigger: 'blur' }],
        // meetUrl:[{ required: true, message: '请输入会议链接', trigger: 'blur' }],
      },
      addForm:{},
      editForm:{},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      InfoTable:[],
      columns: [
        {
          title: '登录账号',
          dataIndex: 'loginAccount',
        },
        {
          title: '会议ID',
          dataIndex: 'meetId',
        },
        {
          title: '容量',
          dataIndex: 'capacity',
        },
        {
          title: '备注',
          dataIndex:  'remark',
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'state'},
        },
        {
          title: '创建人',
          dataIndex: 'createdByName',
        },
        {
          title: '创建时间',
          dataIndex:  'createdTime',
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: {customRender: 'operate'},
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"zoom会议ID管理")
  },
  mounted() {
    this.getLogData(1)
  },
  methods:{
    async getLogData(pageNum,meetId,type){
      const info = await getZoomMeet(pageNum,meetId,this.type)
      if (info.code===0){
        await this.getPerList()
        this.InfoTable=info.data.rows
        this.pagination.total=info.data.count
        this.InfoTable.forEach((value,index)=>{
          this.leaderOperateList.forEach((ids,idIndex)=>{
            if (value.createdById===ids.id){
              value.createdByName = ids.userName
            }
          })
        })
      }
    },
    async getPerList(departmentById) {
      const response = await getCommunictionList(1,9999,1,'',departmentById)
      if(response.code === 0){
        this.leaderOperateList = response.data.rows
      }
    },
    pageChange() {
      this.getLogData(this.pagination.current)
    },
    async switchChange(id,open){
      const data = {
        id:id,
        status : open
      }
      const Info = await  putZoomMeet(data)
      if (Info.code === 0){
        this.getLogData(this.pagination.current)
        this.$message.success('修改成功')
      }else {
        this.$message.error('修改失败' + Info.message)
      }
    },

    addBtn(){
      this.addShow=true
    },
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const addFrom = await postZoomMeet(this.addForm)
          if (addFrom.code === 0){
            await this.getLogData(this.pagination.current)
            this.$message.success("新增成功")
            this.addShow=false
            this.addForm={}
            this.$refs.addItem.resetFields();
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    async editBtn(row){
      // this.editForm=JSON.parse(JSON.stringify(row))
      const info = await getZoomMeet(1,row.meetId)
      this.editForm=info.data.rows[0]
      delete this.editForm.createdById;
      delete this.editForm.createdTime;
      this.editShow=true
    },
    async editOk(){


      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const edit = await putZoomMeet(this.editForm)
          if (edit.code === 0){
            await this.getLogData(this.pagination.current)
            this.$message.success("新增成功")
            this.editShow=false
            this.$refs.editItem.resetFields();
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    searchOk(){
      this.getLogData(1,this.searchID,this.type)
    },
    typeSearchOk(e){
      switch (e.target.value){
        case 'ZOOM':
          this.getLogData(1,this.searchID,'ZOOM')
          this.type='ZOOM'
              break;
        case 'TENCENT':
          this.getLogData(1,this.searchID,'TENCENT')
          this.type='TENCENT'
              break;
        case '':
          this.getLogData(1,this.searchID,'')
          this.type=''
              break;
      }
    },
    searchReset(){
      this.getLogData(1)
      this.searchID=null
      this.type = ""
    },
    async delTable(id){
      const dalId = await delZoomMeet(id)
      if (dalId.code === 0){
        this.$message.success('删除成功');
        await this.getLogData(this.pagination.current)
      }else {
        this.$message.error('删除失败'+dalId.message)
      }

    },
    cancel(){
      this.$message.warning('取消删除');
    },

  },
}
</script>

<style lang="scss" scoped>
.zoomMeet{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .zoomMeet_header{
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .zoomMeet_table{
    margin-top: 10px;
    padding: 15px;
    background: white;
  }
}
</style>
